<template>
  <div class="mind-block" :class="!noFixed ? 'float-editor' : ''">
    <drag-cross
      :direction="crossDirection"
      :up-mode="
        showMDEditor && showMindMap
          ? showEditorToRight
            ? DragCrossMode.rightDisabled
            : DragCrossMode.enabled
          : showMDEditor
          ? DragCrossMode.leftDisabled
          : showMindMap
          ? DragCrossMode.rightDisabled
          : DragCrossMode.disabled
      "
      :down-mode="
        aShowMDEditor && aShowMindMap
          ? DragCrossMode.enabled
          : aShowMDEditor
          ? DragCrossMode.leftDisabled
          : aShowMindMap
          ? DragCrossMode.rightDisabled
          : DragCrossMode.disabled
      "
      @change-direction-finished="onChangeDirectionFinished"
    >
      <template #left-top>
        <mind-map
          v-if="showMindMap"
          ref="mindmap"
          :mindRootItem="mindRootItem"
          :select-item="modelValue"
          :isMindLocalStorage="isMindLocalStorage"
          :mindSelect="onUpMindSelect"
          :changeTitleApi="changeTitleApi"
          :deleteApi="
            async (options) => {
              await onMindDeleteApi(options, 0);
            }
          "
          @change="onChangeRoot"
          @changeTitle="onMindChangeTitle($event, 0)"
          @add="onAdd($event, 0)"
          @add-edge="onAddEdge($event, 0)"
          @change-edge="onChangeEdge($event, 0)"
          @delete-edge="onDeleteEdge($event, 0)"
          @move="onMindMove($event, 0)"
          @correlation="onCorrelation"
        />
        <!-- <svg-icon
          v-if="selectItem"
          icon-class="mind"
          class-name="mind-open"
          @click="openOpenMindMap()"
        /> -->
        <el-button
          v-if="!showMDEditor && !showEditorToRight"
          class="mind-right-btn new-mind-right-btn"
          size="small"
          @click="mindChangeEditor()"
        >
          <svg-icon v-if="!showMDEditor" icon-class="new-61" class-name="" />
          <el-icon v-else>
            <Close />
          </el-icon>
        </el-button>
      </template>
      <template #right-top>
        <drag-view
          class="main-editor"
          v-if="showMDEditor && (!showEditorToRight || (!showMindMap && selectItem))"
          init-top="65"
          init-width="300"
          init-right="0"
          init-bottom="0"
          :no-fixed="noFixed"
        >
          <template #header v-if="showMindMap">
            <editor-header
              v-model:no-fixed="noFixed"
              @close="mindChangeEditor()"
              @change-size="onChangeSize"
            />
          </template>
          <div class="vditor-dom">
            <mxs-editor
              ref="mxsEditor"
              v-if="isTest && selectItem"
              :article-id="selectItem.id"
              v-model:title="selectItem.title"
              @changeTitle="changeTitleApi"
              @update:title="onMDUpdateTitle($event, 0)"
              :exts="selectItem.exts"
            />
            <froala-editor
              v-else-if="selectItem"
              class="right"
              ref="froalaEditorTop"
              :midType="showMindMap ? '' : 'vditor'"
              :articleId="selectItem && selectItem.id"
              v-model:title="selectItem.title"
              @onSaveTitle="changeTitleApi"
              :saveStatus="saveStatus"
              :editorRefresh="editorRefresh"
              :openConfig="openConfig"
              :mindVditor="false"
              :topArticleId="topArticleId"
              :editorTipList="$root.fontList"
              :queryChatgpt="queryChatgpt"
              @update:title="onMDUpdateTitle($event, 0)"
            />
            <link-tree v-if="false" />
          </div>
        </drag-view>
      </template>
      <template #left-bottom>
        <mind-map
          v-if="aShowMindMap"
          ref="subMindmap"
          :mindRootItem="aMindRootItem"
          :select-item="aSelectItem"
          :isMindLocalStorage="aIsMindLocalStorage"
          :mindSelect="onMindSelect"
          :changeTitleApi="changeTitleApi"
          :correlation-change-to-root="true"
          :local-data="true"
          :p-edges="aEdges"
          :map-data="aMindMapData"
          :deleteApi="
            async (options) => {
              await onMindDeleteApi(options, 1);
            }
          "
          @changeTitle="onMindChangeTitle($event, 1)"
          @add="onAdd($event, 1)"
          @add-edge="onAddEdge($event, 1)"
          @change-edge="onChangeEdge($event, 1)"
          @delete-edge="onDeleteEdge($event, 1)"
          @move="onMindMove($event, 1)"
          @change="onChangeRoot"
        />
        <!-- <svg-icon
          icon-class="mind"
          class-name="mind-open"
          @click="openSubMindMap()"
        /> -->
        <el-button
          v-if="!aShowMDEditor"
          class="mind-right-btn"
          size="small"
          @click="aMindChangeEditor()"
        >
          <el-icon>
            <Document v-if="!aShowMDEditor" />
            <Close v-else />
          </el-icon>
        </el-button>
      </template>
      <template #right-bottom>
        <div class="vditor-dom" v-if="aShowMDEditor">
          <froala-editor
            v-if="aShowMDEditor && aSelectItem"
            class="right"
            ref="froalaEditor"
            toolbar-id="rToolsButton"
            :midType="aShowMindMap ? '' : 'vditor'"
            :articleId="aSelectItem && aSelectItem.id"
            v-model:title="aSelectItem.title"
            :saveStatus="saveStatus"
            :editorRefresh="editorRefresh"
            :openConfig="openConfig"
            :mindVditor="false"
            :topArticleId="aTopArticleId"
            :editorTipList="$root.fontList"
            :queryChatgpt="queryChatgpt"
            @onSaveTitle="changeTitleApi"
            @update:title="onMDUpdateTitle($event, 1)"
          />
        </div>
        <el-button
          v-if="aShowMindMap && aShowMDEditor"
          class="mind-right-btn"
          size="small"
          @click="aMindChangeEditor()"
        >
          <el-icon>
            <Document v-if="!aShowMDEditor" />
            <Close v-else />
          </el-icon>
        </el-button>
      </template>
    </drag-cross>
    <!--    <drag-view class="main-editor"-->
    <!--               v-if="showMDEditor"-->
    <!--               :init-top="65"-->
    <!--               :no-fixed="!showMindMap">-->
    <!--      <template #header>-->
    <!--        sss-->
    <!--      </template>-->
    <!--      <div class="vditor-dom">-->
    <!--        <froala-editor-->
    <!--            v-if="selectItem"-->
    <!--            class="right"-->
    <!--            ref="froalaEditorTop"-->
    <!--            :midType="showMindMap ? '' : 'vditor'"-->
    <!--            :articleId="selectItem && selectItem.id"-->
    <!--            v-model:title="selectItem.title"-->
    <!--            @onSaveTitle="changeTitleApi"-->
    <!--            :saveStatus="saveStatus"-->
    <!--            :editorRefresh="editorRefresh"-->
    <!--            :openConfig="openConfig"-->
    <!--            :mindVditor="false"-->
    <!--            :topArticleId="topArticleId"-->
    <!--            :editorTipList="$root.fontList"-->
    <!--            :queryChatgpt="queryChatgpt"-->
    <!--            @update:title="onMDUpdateTitle($event, 0)"-->
    <!--        />-->
    <!--      </div>-->
    <!--    </drag-view>-->
  </div>
</template>
<script>
import DragRight from "./document/DragRight";
import FroalaEditor from "./froala-editor";
import MindMap from "./mind-map";
import DragCross from "./widget/DragCross";
import { DragCrossMode } from "../../../utils/constaints";
import DragView from "./widget/DragView";
import EditorHeader from "./EditorHeader";
import MxsEditor from "../../../components/mxs-editor";
import LinkTree from "./tree/LinkTree";
export default {
  components: {
    LinkTree,
    EditorHeader,
    DragView,
    DragCross,
    DragRight,
    FroalaEditor,
    MindMap,
    MxsEditor,
  },
  props: {
    isMainView: { type: Boolean, default: false },
    showEditorToRight: { type: Boolean, default: false },
    showMindMap: { type: Boolean, default: false },
    showMDEditor: { type: Boolean, default: true },
    mindRootItem: { type: Object, default: undefined },
    modelValue: { type: Object, default: undefined },
    isMindLocalStorage: { type: Boolean, default: undefined },
    mindSelect: { type: Function, default: undefined },
    changeTitleApi: { type: Function, default: undefined },
    deleteApi: { type: Function, default: undefined },
    saveStatus: { type: Function, default: undefined },
    editorRefresh: { type: Function, default: undefined },
    openConfig: { type: Function, default: undefined },
    queryChatgpt: { type: Function, default: undefined },
    openMindMap: { type: Function, default: undefined },
    crossDirection: { type: String, default: "row" },
  },
  data() {
    return {
      // isTest: localStorage.getItem("__test") === "1",
      isTest: true,
      allowMultiWin: true,
      DragCrossMode,
      changeContent: false,

      aShowMindMap: false,
      aMindRootItem: undefined,
      aMindMapData: undefined,
      aIsMindLocalStorage: false,
      aShowMDEditor: false,
      aSelectItem: undefined,
      aEdges: [],
      noFixed: true,
    };
  },
  watch: {
    allowMultiWin(val, old) {
      if (!val) {
        this.aShowMDEditor = false;
        this.aShowMindMap = false;
      }
    },
    showMindMap(val, old) {
      if (!val && !this.showMDEditor) {
        this.$emit("update:showMDEditor", true);
      }
    },
    modelValue(val, old) {
      if (val !== old && this.showMindMap && val) {
        if (this.$root.config.alwaysMind !== 4) {
          requestAnimationFrame(() => {
            let isMi = this.$refs.mindmap?.getGraph()?.editSelectedNodeById(val.id);
            if (!isMi) {
              this.$emit("update:mindRootItem", val);
            }
          });
        }
      }
    },
  },
  computed: {
    aTopArticleId: {
      get() {
        let topId = "";
        let item = this.aSelectItem;
        while (item) {
          topId = item.id;
          item = item.parent;
        }
        return topId;
      },
    },
    selectItem: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
    topArticleId: {
      get() {
        let topId = "";
        let item = this.selectItem;
        while (item) {
          topId = item.id;
          item = item.parent;
        }
        return topId;
      },
    },
  },
  methods: {
    history() {
      this.$refs.froalaEditorTop?.history();
    },
    onMindMove(data, tag) {
      if (tag === 1 && this.$refs.mindmap?.graph) {
        this.$refs.mindmap?.graph.moveNode(data);
      } else if (tag === 0 && this.$refs.subMindmap?.graph) {
        this.$refs.subMindmap?.graph.moveNode(data);
      }
    },
    onChangeDirectionFinished() {
      setTimeout(() => {
        // if (this.$refs.mindmap?.graph) {
        //   this.$refs.mindmap?.graph.editFitCenter();
        // }
        // if (this.$refs.subMindmap?.graph) {
        //   this.$refs.subMindmap?.graph.editFitCenter();
        // }
      }, 50);
    },
    onAddEdge(data, tag) {
      if (tag === 1 && this.$refs.mindmap?.graph) {
        this.$refs.mindmap?.graph.editAddEdge(data.data);
      } else if (tag === 0 && this.$refs.subMindmap?.graph) {
        this.$refs.subMindmap?.graph.editAddEdge(data.data);
      }
    },
    onChangeEdge(data, tag) {
      if (tag === 1 && this.$refs.mindmap?.graph) {
        this.$refs.mindmap?.graph.editChangeEdge(data);
      } else if (tag === 0 && this.$refs.subMindmap?.graph) {
        this.$refs.subMindmap?.graph.editChangeEdge(data);
      }
    },
    onDeleteEdge(data, tag) {
      if (tag === 1 && this.$refs.mindmap?.graph) {
        this.$refs.mindmap?.graph.deleteEdgeByModel(data.data, false);
      } else if (tag === 0 && this.$refs.subMindmap?.graph) {
        this.$refs.subMindmap?.graph.deleteEdgeByModel(data.data, false);
      }
    },
    onAdd(data, tag = 2) {
      if ((tag === 1 || tag === 2) && this.$refs.mindmap?.graph) {
        this.$refs.mindmap?.graph.editAddNodeById(data.id, data.item);
      }
      if ((tag === 0 || tag === 2) && this.$refs.subMindmap?.graph) {
        this.$refs.subMindmap?.graph.editAddNodeById(data.id, data.item);
      }
    },
    async onTreeMove(data, isLocal) {
      let p = await this.$api.edges.getNodes({ id: data.articleId }, isLocal);
      if (this.$refs.mindmap?.graph && this.isMindLocalStorage === isLocal) {
        this.$refs.mindmap?.graph.moveNode({
          parentId: data.parentId,
          moveId: data.articleId,
          sort: data.sort,
          p,
        });
      }
      if (this.$refs.subMindmap?.graph && this.aIsMindLocalStorage === isLocal) {
        this.$refs.subMindmap?.graph.moveNode({
          parentId: data.parentId,
          moveId: data.articleId,
          sort: data.sort,
          p,
        });
      }
    },
    checkCollapsed(item, data) {
      data.collapsed = item.collapsed;
      if (item.children && item.children.length > 0) {
        item.children.forEach((child, idx) => {
          this.checkCollapsed(child, data.children[idx]);
        });
      }
    },
    onTreeClone(data, isLocal) {
      if (data.itemId) {
        const item = this.$refs.mindmap?.graph.findDataById(data.itemId);
        this.checkCollapsed(item, data.newItem);
      }
      if (this.$refs.mindmap?.graph) {
        this.$refs.mindmap?.graph.cloneTo(data.newItem);
      }
      if (this.$refs.subMindmap?.graph) {
        this.$refs.subMindmap?.graph.cloneTo(data.newItem);
      }
    },
    onMindChangeTitle(item, tag) {
      if (tag === 1 && this.$refs.mindmap?.graph) {
        this.$refs.mindmap?.graph.changeNodeTitle(item);
      } else if (tag === 0 && this.$refs.subMindmap?.graph) {
        this.$refs.subMindmap?.graph.changeNodeTitle(item);
      }
    },
    onMDUpdateTitle(title, tag) {
      if (tag === 0) {
        this.changeTitle(this.selectItem.id, title);
      } else {
        this.changeTitle(this.aSelectItem.id, title);
      }
    },
    aMindChangeEditor() {
      this.aShowMDEditor = !this.aShowMDEditor;
      if (this.$refs.subMindmap) {
        // setTimeout(()=>{
        //   this.$refs.subMindmap?.graph?.editFitCenter();
        // }, 50)
      }
    },
    onUpMindSelect(item) {
      this.mindSelect(item);
    },
    onMindSelect(item) {
      this.aSelectItem = item;
      this.aShowMDEditor = true;
    },
    onCorrelation({ model, edges }) {
      this.aIsMindLocalStorage = this.isMindLocalStorage;
      this.aEdges = edges;
      this.aMindMapData = model;
      this.aMindRootItem = this.$root.findItemById(model.id);
      this.aShowMindMap = true;
      this.aSelectItem = undefined;
    },
    openSubMindMap() {
      this.aShowMindMap = !this.aShowMindMap;
    },
    openOpenMindMap() {
      if (this.selectItem) {
        this.openMindMap &&
          this.openMindMap(
            { item: this.selectItem },
            this.selectItem.id.startsWith("local-")
          );
      }
    },
    async onMindDeleteApi(options, tag) {
      if (tag < 3) {
        await this.deleteApi(options.id);
      }
      if (tag === 1 || tag === 3) {
        if (options.id === this.$refs.mindmap?.graph?.get("data").id) {
          this.selectItem = undefined;
          this.$emit("update:showMindMap", false);
        } else {
          this.$refs.mindmap?.graph?.deleteNodeById(options);
        }
      }

      if (tag === 0 || tag === 3) {
        if (options.id === this.$refs.mindmap?.graph?.get("data").id) {
          this.aSelectItem = undefined;
          this.aMindRootItem = undefined;
          this.aShowMindMap = false;
        } else {
          this.$refs.subMindmap?.graph?.deleteNodeById(options);
        }
      }
    },
    onChangeRoot(node) {
      const item = this.$root.findItemById(node.id);
      if (item) {
        this.$emit("update:mindRootItem", item);
        this.$emit("update:modelValue", undefined);
      }
    },
    mindChangeEditor() {
      this.$emit("update:showMDEditor", !this.showMDEditor);
      if (this.$refs.mindmap) {
        // setTimeout(()=>{
        //   this.$refs.mindmap?.graph?.editFitCenter();
        // }, 50)
      }
    },
    onChangeSize(type) {
      switch (type) {
        case "fixed":
          this.noFixed = false;
          break;
        case "right":
          this.noFixed = true;
          this.$emit("update:crossDirection", "row");
          break;
        case "bottom":
          this.noFixed = true;
          this.$emit("update:crossDirection", "column");
          break;
      }
    },
    changeTitle(id, title) {
      if (this.$refs.mindmap?.graph) {
        this.$refs.mindmap?.graph.changeNodeTitle({ id, title });
      }
      if (this.$refs.subMindmap?.graph) {
        this.$refs.subMindmap?.graph.changeNodeTitle({ id, title });
      }
    },
    isParentId(id) {
      if (!this.mindRootItem) return false;
      let parent = this.mindRootItem.parent;
      while (parent) {
        if (parent.id === id) return true;
        parent = parent.parent;
      }
      return false;
    },
    checkNodeExist(id) {
      if (this.$refs.mindmap?.graph) {
        return !!this.isParentId(id) || !!this.$refs.mindmap?.graph.findDataById(id);
      }
      return false;
    },
    openSameMap(item) {
      if (this.$refs.mindmap?.graph) {
        this.$refs.mindmap?.openSameMap(item);
      }
    },
    isDoubleMap(articleId) {
      if (this.$refs.mindmap?.graph) {
        return this.$refs.mindmap?.isDoubleMap(articleId);
      }
      return false;
    },
  },
};
</script>
